.navbar {
	padding: 0;
	margin: 0;
	min-height: 80px; /* Ajuste a altura conforme necessário */
	overflow: hidden; /* Garante que nada "exploda" para fora */
}

.navbar-header {
	display: flex;
	align-items: center;
}
.navbar-brand {
	padding: 0;
}

.navbar-brand img {
	max-width: 85%; /* Limite a largura do logo */
	height: auto; /* Mantém a proporção */
	display: block;
	border-radius: 50px; /* Adicione este valor conforme desejado */
}

@media screen and (max-width: 400px) {
	#features,
	#mvv {
		padding: 20px;
		width: 111%;
	}
	#about,
	#servicos,
	#testimonials,
	#mvv,
	#team,
	#contact,
	#footer {
		width: 111%;
	}

	#portfolio {
		width: 110%;
	}
	.navbar {
		padding: 10px; /* Ajuste o padding se necessário */
	}
}
